<template>
  <div
    :class="{ 'bgImage': !isLogged, 'invite-background-color': isLogged }"
    class=" centerAll"
  >
    <div class="full-container centerAll is-flex-box-column">
      <card external-card="card container material-card card-form-container">
        <section
          slot="Pre-header"
          class="company-invite-container modal-card-body centerAll is-flex-box-column"
        >
          <img
            class="icon-invite"
            src="@/assets/img/logoName.svg"
            alt="Boardgent logo"
          >
          <div
            v-if="!loadingComponent.isActive"
          >
            <p
              class="title title-invite invite-margin-top"
            >
              {{ title }}
            </p>
            <p v-if="exitCode === operationalApiErrors.EXPIRED_INVITATION.value.code">
              Please advise the person who invited you to invite you again.
            </p>
            <div v-else-if="exitCode === 0">
              <p>
                Congratulations, you've become a {{ companyName }} user.
              </p>
              <div class="has-text-centered invite-margin-top">
                <button
                  type="submit"
                  class="button is-primary"
                  @click="goToCompany()"
                >
                  <p>Go to Company</p>
                </button>
              </div>
            </div>
            <p v-else-if="exitCode === operationalApiErrors.INVALID_INVITATION.value.code">
              Please verify the link is and try again.
            </p>
            <p
              v-else-if="exitCode === operationalApiErrors
                .INVALID_EMAIL_FOR_INVITATION.value.code"
            >
              The user is not in the company guest list. Please verify the link and try again.
            </p>
            <p v-else-if="exitCode === programmerApiErrors.GENERAL_ERROR.value.code">
              Please try again.
            </p>
          </div>
        </section>
      </card>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import card from '@/components/cross/Card.vue';
import LodingMixin from '@/mixins/loading';
import vtulEnums from '../../../cross/index';

const { operationalApiErrors, programmerApiErrors } = vtulEnums.enum.api;
const { programmerUiErrors } = vtulEnums.enum.ui;

export default {
  name: 'CompanyInvitation',
  metaInfo: {
    title: 'Invitation',
  },
  components: {
    card,
  },
  mixins: [LodingMixin],
  data() {
    return {
      title: '',
      companyName: '',
      operationalApiErrors,
      programmerApiErrors,
      exitCode: programmerUiErrors.GENERAL_ERROR.value.code,
      registerError: programmerUiErrors.GENERAL_ERROR.value.code,
      redirectUrl: '/login',
      isLogged: false,
    };
  },
  mounted() {
    this.openLoading();
    this.validateInvite();
  },
  methods: {
    ...mapActions('company-management', { validateInvitation: 'create' }),
    goToCompany() {
      this.$router.push(this.redirectUrl);
    },
    // Verify that the invitation is valid and has not expired.
    isRegisterErrorCallback() {
      this.registerError = this.$route.params.token;
      return this.registerError === programmerApiErrors.GENERAL_ERROR.value.code
        || this.registerError === operationalApiErrors.INVALID_EMAIL_FOR_INVITATION.value.code;
    },
    validateInvite() {
      if (!this.isRegisterErrorCallback()) {
        this.validateInvitation({
          action: 'assignInvitation',
          token: this.$route.params.token,
        }).then((response) => {
          this.companyName = response.companyName;
          this.$store.dispatch('auth/authenticate').then(() => {
            // If the user is authenticated, redirect him to the company that was invited.
            this.title = 'Activation Successful';
            this.exitCode = 0;
            this.redirectUrl = `/${response.companyId}/devices`;
            this.isLogged = true;
          }).catch((err) => {
            // If the user exists and is not authenticated,
            // it shows the company's activation message.
            if (response.id !== operationalApiErrors.NEED_USER_CREATION.value.code) {
              this.title = 'Activation Successful';
              this.redirectUrl += `?returnUrl=${response.companyId}/devices`;
              this.exitCode = response.id;
            } else {
              // if the user does not exist redirect him to the registry view.
              this.$router.push(`/register/${this.$route.params.token}/${response.companyId}`);
            }
            throw err;
          });
        }).catch((err) => {
          this.title = err.message;
          this.exitCode = err.code;
          throw err;
        }).finally(() => {
          this.closeLoading();
        });
      } else {
        this.title = 'Oops, an error ocurred!';
        this.exitCode = this.registerError;
        this.closeLoading();
      }
    },
  },
};
</script>
<style>

  .title-invite{
    margin-top: 1.7rem !important;
    font-size: 1.4rem;
    text-align: center;
  }

  .invite-background-color{
    background-color: #f9f9f9;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .icon-invite{
    max-width: 260px;
  }

  .is-flex-box-column{
    flex-flow: column;
  }

  .full-container{
    width: 100%;
  }

  .invite-margin-top {
    margin-top: 1.2rem;
  }

  .company-invite-container{
    width: 500px;
    height: 275px;
    border-radius: 10px;
  }

  @media (min-width: 320px) and (max-width: 500px) {
    .company-invite-container{
      width: auto;
      height: auto;
      margin: 2rem;
    }

    .full-container > card, .full-container > card > card{
      width: 100%;
    }
  }

</style>
